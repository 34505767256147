import axios from "axios";
import {
  linkPlaceholders,
  navLinks,
  routing,
  TableView,
  useCategories,
  useLoginContext,
  baseURL,
  Button,
  DropdownButton,
  useExportFile,
  useAxios,
} from "common";
import React, { useState, useEffect, useCallback } from "react";
import { toast, ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./CustomToast.css"; // Import your custom CSS file
import { ThreeDots } from "react-loader-spinner"; // Import ThreeDots loader
import { useNavigate } from "react-router-dom";
const ExportButton = () => {
  const { getThisFile, exportLoading } = useExportFile(
    "PriceSheets",
    `/brands/exportPricesheets`
  );
  return (
    <Button loading={exportLoading} onClick={() => getThisFile()}>
      Export
    </Button>
  );
};

const ExportAllItemsButton = () => {
  const { getThisFile, exportLoading } = useExportFile(
    "Brand Items",
    `/brands/exportAllItems`
  );
  return (
    <Button loading={exportLoading} onClick={() => getThisFile()}>
      Download All Brand Items
    </Button>
  );
};

const BulkImport = ({ isModalOpen, setIsModalOpen }) => {
  const { getThisFile } = useExportFile(
    "Bulk import sample",
    `/brands/bulkSample`
  );
  const options = [
    <div key="downloadSample" onClick={() => getThisFile()}>
      Download Sample
    </div>,
    <div onClick={() => setIsModalOpen(!isModalOpen)} key="uploadFile">
      Upload file
    </div>,
  ];

  return (
    <DropdownButton secondary fit options={options}>
      Bulk Import
    </DropdownButton>
  );
};

const ModalWindow = ({ isModalOpen, setIsModalOpen, file, setFile }) => {
  const { callAxios } = useAxios();
  const [formData, setFormData] = useState(new FormData());
  const [loading, setLoading] = useState(false); // State to handle loader

  useEffect(() => {
    if (file) {
      setFormData((prev) => {
        prev.set("file", file, "Bulk import.xlsx");
        return prev;
      });
    }
  }, [file]);

  const handleFileUpload = () => {
    setLoading(true); // Start loader
    callAxios({
      method: "PUT",
      url: "/brands/bulk/update",
      data: formData,
    })
      .then(() => {
        toast.success("File imported successfully!", {
          className: "custom-toast",
        });
      })
      .catch((error) => {
        toast.error(`File import failed: ${error.message}`, {
          className: "custom-toast custom-toast-error",
        });
      })
      .finally(() => {
        setLoading(false); // Stop loader
        setIsModalOpen(!isModalOpen);
      });
  };

  return (
    <div
      onClick={() => setIsModalOpen(!isModalOpen)}
      style={{
        display: isModalOpen ? "flex" : "none",
        height: "100vh",
        width: "100%",
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: "#0000007e",
        zIndex: 5,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          background: "white",
          padding: 25,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: 20,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {loading ? (
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#00BFFF"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        ) : (
          <>
            <input onChange={(e) => setFile(e.target.files[0])} type="file" />
            <Button onClick={handleFileUpload}>Submit</Button>
          </>
        )}
      </div>
    </div>
  );
};

const CustomTableView = ({ data, tableConfig }) => {
  return (
    <table
      style={{
        width: "100%",
        borderCollapse: "collapse",
        marginTop: "20px",
        textAlign: "center",
      }}
    >
      <thead>
        <tr>
          {tableConfig.map((col) => (
            <th
              key={col.name}
              style={{ border: "1px solid black", padding: "8px" }}
            >
              {col.header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {tableConfig.map((col) => (
              <td
                key={col.name}
                style={{ border: "1px solid black", padding: "8px" }}
              >
                {row[col.name]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const BrandList = () => {
  const { isPriceSheetsAdmin } = useLoginContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredDataByQuery, setFilteredDataByQuery] = useState([]);
  const [filteredDataByCutoffDates, setFilteredDataByCutoffDates] = useState(
    []
  );
  const [startCutoffDate, setStartCutoffDate] = useState(null);
  const [endCutoffDate, setEndCutoffDate] = useState(null);
  const navigate = useNavigate();
  const adminProps = isPriceSheetsAdmin
    ? {
        deleteUrl: (id) => `/brands/${id}`,
        deleteMessage: (brand) => `Delete ${brand.name}`,
        actionLink: routing.brands.add,
        actionName: "Add Brand",
      }
    : {};

  const fetchFilteredDataByQuery = async (query) => {
    try {
      if (query) {
        const response = await axios.get(
          `${baseURL}/itemSearch?query=${query}`
        );
        const itemsData = response.data.map((item) => ({
          barcode: item.barcode,
          sku: item.sku,
          description: item.description,
          brandName: item.brand.name,
          price: item.costPrice,
        }));
        setFilteredDataByQuery(itemsData);
      } else {
        setFilteredDataByQuery([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchFilteredDataByCutoffDates = useCallback(
    async (start, end) => {
      try {
        if (start && end) {
          const response = await axios.get(`${baseURL}/filterByCutoffDate`, {
            params: {
              startCutoffdate: start.getDate(),
              endCutoffdate: end.getDate(),
            },
          });
          setFilteredDataByCutoffDates(
            response.data.map((item) => ({
              _id: item._id,
              name: (
                <span
                  style={{
                    color: "black",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                  onClick={() => navigate(`/brands/${item._id}/view`)}
                >
                  {item.name}
                </span>
              ),
              minimumMargin: item?.minimumMargin,
              maximumMargin: item?.maximumMargin,
              shippingCost: item?.shippingCost,
              commissionCost: item?.commissionCost,
              minimumAmount: item?.minimumOrderDollarAmount,
              skuassortment: item?.skuassortment,
            }))
          );
        } else {
          setFilteredDataByCutoffDates([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    [navigate]
  );

  useEffect(() => {
    fetchFilteredDataByQuery(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    fetchFilteredDataByCutoffDates(startCutoffDate, endCutoffDate);
  }, [startCutoffDate, endCutoffDate, fetchFilteredDataByCutoffDates]);

  const { categories } = useCategories();
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const inputStyle = {
    padding: "0.5rem",
    height: "38px",
    width: "25%",
    boxSizing: "border-box",
  };

  const datePickerInputStyle = {
    height: "38px",
    padding: "0.5rem",
    fontSize: "16px",
    boxSizing: "border-box",
    width: "200px",
    textAlign: "center",
  };
  const tableConfigForQuery = [
    { name: "barcode", header: "Barcode" },
    { name: "sku", header: "SKU" },
    { name: "description", header: "Description" },
    { name: "brandName", header: "Brand Name" },
    { name: "price", header: "Price" },
  ];

  const tableConfigForCutoffDates = [
    { name: "name", header: "Name" },
    { name: "minimumMargin", header: "Minimum Margin" },
    { name: "maximumMargin", header: "Maximum Margin" },
    { name: "shippingCost", header: "Shipping Cost" },
    { name: "commissionCost", header: "Commission Cost" },
    { name: "minimumAmount", header: "Minimum Order Amount" },
    { name: "skuassortment", header: "SKU Assortment" },
  ];
  const defaultTableConfig = [
    { name: "name", header: "Name" },
    { name: "isInactive", header: "Active/Inactive" },
    {
      name: "lastModifiedPricesheet",
      header: "Items Modified",
      isDate: true,
    },
    { name: "pricesheets", header: "Pricesheets", center: true },
    { name: "lastUploadedBy", header: "Last Uploaded By" },
  ];

  const filterConfig = [
    {
      name: "category",
      type: "dropdown",
      options: categories,
      label: "Filter By Category",
    },
    {
      name: "name",
      type: "input",
      label: "Search",
    },
  ];

  return (
    <>
      <ToastContainer
        className="custom-toast-container"
        position="top-center"
        autoClose={1000} // Auto-close after 1 second
        hideProgressBar
        closeOnClick
        pauseOnHover
        draggable
        transition={Slide}
        toastClassName="custom-toast"
      />
      {isModalOpen && (
        <ModalWindow
          setFile={setFile}
          file={file}
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
        />
      )}

      <div style={{ marginBottom: "20px" }}>
        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <label>Item Search: </label>
          <input
            type="text"
            name="itemSearch"
            placeholder="Search Items"
            onChange={handleSearchChange}
            style={inputStyle}
          />
          <label>Start:</label>
          <DatePicker
            selected={startCutoffDate}
            onChange={(date) => setStartCutoffDate(date)}
            placeholderText="Start Date"
            dateFormat="MM/dd/yyyy"
            isClearable
            customInput={<input style={datePickerInputStyle} />}
          />
          <label>End:</label>
          <DatePicker
            selected={endCutoffDate}
            onChange={(date) => setEndCutoffDate(date)}
            placeholderText="End Date"
            dateFormat="MM/dd/yyyy"
            isClearable
            customInput={<input style={datePickerInputStyle} />}
          />
        </div>
        {filteredDataByQuery.length > 0 ? (
          <CustomTableView
            data={filteredDataByQuery}
            tableConfig={tableConfigForQuery}
          />
        ) : filteredDataByCutoffDates.length > 0 ? (
          <CustomTableView
            data={filteredDataByCutoffDates}
            tableConfig={tableConfigForCutoffDates}
          />
        ) : (
          <TableView
            url="/brands"
            tableConfig={defaultTableConfig}
            navLinks={
              navLinks[isPriceSheetsAdmin ? "brandsForAdmin" : "brands"]
            }
            linkParam={linkPlaceholders.brandId}
            header="Brands"
            filterConfig={filterConfig}
            {...adminProps}
            additionalActions={[
              <ExportButton key="exportButton" />,
              <BulkImport
                key="Bulk"
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
              />,
              <ExportAllItemsButton key="All Items" />,
            ]}
            shapeData={(d) =>
              d.data.data.map((brand) => ({
                ...brand,
                pricesheets: (brand.pricesheets?.length || 0) + 1,
                lastUploadedBy: brand.lastUploadedBy?.name,
                isInactive: brand.isInactive ? "In-Active" : "Active",
              }))
            }
            defaultFilters={{ brand: { $exists: false } }}
            defaultParams={{
              populate: "pricesheets lastUploadedBy",
              sort: "-lastModifiedPricesheet",
            }}
          />
        )}
      </div>
    </>
  );
};

export default BrandList;
